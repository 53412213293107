<template>
  <div class="q-pa-md">
    <q-card>
      <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
        <q-legend
          :label="$t('Automation Rules')"
          text-class="text-h6"
        />

        <search
          dense
          autoset
          is-expandable
          @submit="handleSearch"
        />

        <q-space />

        <q-btn
          color="dark"
          text-color="white"
          :label="$t('Refresh')"
          size="sm"
          class="q-mr-sm"
          no-caps
          unelevated
          @click="refreshItems"
        />

        <q-btn
          :color="serverParams.filter && serverParams.filter.length > 0 ? 'light-blue-9' : 'dark'"
          text-color="white"
          size="sm"
          class="q-mr-sm"
          :label="filterBtnText"
          no-caps
          unelevated
          @click="openCloseFilters"
        />

      </q-card-section>

      <q-card-section class="q-ma-none q-pa-none">
          <filters-collapse
            :is-open="isOpenFilter"
            :options="{
              defaultFilter: serverParams.filter,
              fields: activatedFields,
              values: {
              },
              style: {
                noGroups: true
              }
            }"
            @submit="handleFiltersSubmit"
            @close="openCloseFilters"
          />
      </q-card-section>

      <q-card-section class="q-ma-none q-pa-none">
        <q-table
          style="height: calc(100vh - 130px);"
          class="sticky-header-table"
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="automationRules"
          :columns="columns"
          v-model:pagination="pagination"
          :loading="automationRulesLoading"
          :filter="filter"
          virtual-scroll
          binary-state-sort
          flat
          @request="onRequest"
        >
          <template v-slot:loading>
            <q-inner-loading
              showing
              color="primary"
            />
          </template>

          <template v-slot:body="props">
            <q-tr
              :props="props"
              class="clickable"
            >
              <q-td
                key="number"
                :props="props"
                auto-width
              >
                {{ props.rowIndex +  1 }}
              </q-td>

              <q-td
                key="id"
                :props="props"
                class="text-subtitle1"
              >
                <strong>{{ props.row.id }}</strong>
              </q-td>

              <q-td
                key="owner"
                :props="props"
              >
                <span v-if="props.row._embedded.owner">
                  {{ props.row._embedded.owner.name }}
                </span>
              </q-td>

              <q-td
                key="entityClass"
                :props="props"
              >
                {{ props.row.entityClass }}
              </q-td>

              <q-td
                key="created"
                :props="props"
              >
                {{ $moment(props.row.created.date).format(appOptions.formats.date) }}
              </q-td>

              <q-td
                key="state"
                :props="props"
              >
                <q-toggle
                  color="light-blue-9"
                  :model-value="props.row.state === 'active'"
                  @update:model-value="handleStateChange(props.row)"
                />
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Search from '../../components/search/Search.vue'

// Utils
import { buildQuery } from '../../utils/query-utils'

// Mixins
import TableMixin from './../../components/global/TableMixin'
import FiltersCollapse from "@/apps/app/components/filters/FilterCollapse.vue";

export default {
  name: 'Rules',
  components: {
    FiltersCollapse,
    Search
  },
  mixins: [
    TableMixin
  ],
  data () {
    return {
      filter: '',
      isOpenFilter: false,
      pagination: {
        descending: true,
        draw: 1,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      activatedFields: [
        'id',
        'owner',
        'entityClass',
        'created.from',
        'created.to',
      ],
      columns: [
        {
          label: '#',
          name: 'number',
          align: 'left'
        },
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Owner'),
          name: 'owner',
          align: 'left'
        },
        {
          label: this.$t('Entity Class'),
          name: 'entityClass',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left'
        },
        {
          label: this.$t('State'),
          name: 'state',
          align: 'center'
        }
      ],
      stateColors: {
        active: 'success',
        inactive: 'dark'
      }
    }
  },
  computed: {
    ...mapGetters([
      'automationRules',
      'automationRulesNumber',
      'automationRulesLoading',
      'appOptions'
    ]),
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
        ? this.$t('Filtered: ') + this.automationRulesNumber
        : this.$t('Filter')
    }
  },
  mounted () {
    this.loadDefaultItems()
  },
  methods: {
    ...mapActions([
      'loadAutomationRules'
    ]),
    ...mapMutations([
      'setAutomationRule',
      'upsertAutomationRule'
    ]),
    openCloseFilters () {
      this.isOpenFilter = !this.isOpenFilter
    },
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    handleStateChange (item) {
      const invertedState = {
        active: 'inactive',
        inactive: 'active'
      }

      const state = invertedState[item.state]

      return this.$service.automationRules.save({ state }, item.id)
        .then(item => {
          this.upsertAutomationRule(item)
        })
    },
    loadDefaultItems () {
      return this.onRequest({})
    },
    handleFiltersSubmit (filter) {
      let updatedFilter = [
        ...filter
      ]

      this.isOpenFilter = false
      return this.onRequest({ pagination: { filter: updatedFilter, page: 1 }})
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      this.updateParams(query)

      return this.loadAutomationRules(this.serverParams)
        .then(({ page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }
        })
    },
    handleSearch (search) {
        return this.onRequest({ pagination: { page: 1, search } })
    },
    create () {
        this.$router.push('/settings/automation/rules/entity')
    },
    onRowDblClick (row) {
        this.setAutomationRule(row)
        this.$router.push('/settings/automation/rules/entity/' + row.id)
    }
  }
}
</script>
